import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {LoginComponent} from './components/login/login.component';
import {AuthGuard} from './guards/auth.guard';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';

const routes: Routes = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'home', component: HomeComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard]},
    {
        path: 'productsList/:docType/:docNumber',
        loadChildren: './components/products/products.module#ProductsModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'productsList',
        loadChildren: './components/products/products.module#ProductsModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'simulacion',
        loadChildren: './components/simulacion/simulacion.module#SimulacionModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'verificacion',
        loadChildren: './components/verificacion/verificacion.module#VerificacionModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'clienteDetails',
        loadChildren: './components/clients/clients.module#ClientsModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'validacion',
        loadChildren: './components/validacion/validacion.module#ValidacionModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'bandeja',
        loadChildren: './components/bandeja/bandeja.module#BandejaModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/parametros',
        loadChildren: './components/admin/parametro/parametro.module#ParametroModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },    
    {
        path: 'admin/empresas',
        loadChildren: './components/admin/empresa/empresa.module#EmpresaModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/reportes',
        loadChildren: './components/admin/reporte/reporte.module#ReporteModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/usuarios',
        loadChildren: './components/admin/usuario/usuario.module#UsuarioModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/bandeja',
        loadChildren: './components/admin/bandeja/bandeja.module#BandejaModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/notification',
        loadChildren: './components/admin/notification/notification.module#NotificationModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/logs',
        loadChildren: './components/admin/logs/logs.module#LogsModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/auditoria-general',
        loadChildren: './components/admin/auditoria-general/auditoria-general.module#AuditoriaGeneralModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'restricciones-tc',
        loadChildren: './components/restricciones-tc/restricciones-tc.module#RestriccionesTcModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'seguro',
        loadChildren: './components/seguro/seguro.module#SeguroModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/services',
        loadChildren: './components/admin/services-configuration/services-configuration.module#ServicesConfigurationModule',
       // runGuardsAndResolvers: 'always',
        //canActivate: [AuthGuard]
    },
    {
        path: 'admin/auditoria-login',
        loadChildren: './components/admin/auditoria-login/auditoria-login.module#AuditoriaLoginModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'creditcard',
        loadChildren: './components/creditcard/creditcard.module#CreditCardModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'consult-products',
        loadChildren: './components/consult-products/consult-products.module#ConsultProductsModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {path: '**', component: NotFoundComponent, data: {title: 'Page Not Found'}}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
