import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SolicitudPut } from '../../../../models/solicitud';
import { VerificationDactilar } from '../../../../models/verification';
import { SolicitudService } from '../../../../services/solicitud.service';
import { VerificationService } from '../../../../services/verification.service';
import { AppConstants, MONITOR_ANSWER_CODE, MONITOR_AUTENTICATION_TYPE } from '../../../constants/app.constants';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ClientService } from '../../../../services/client.service';
import { StorageService } from '../../../services/storage.service';
import { Platform } from '@ionic/angular';
import * as FileSaver from 'file-saver';
import { NotificationService } from '../../../../services/notification.service';

@Component({
    selector: 'app-request-status-modal',
    templateUrl: './request-status-modal.component.html',
    styleUrls: ['./request-status-modal.component.css'],
})
export class RequestStatusModalComponent implements OnInit {
    arrayMessages = [];
    isLoadingProcess = false;
    model: SolicitudPut;
    type: string;
    verificacion: VerificationDactilar;
    messagestatusResult = '';
    classmessage = '';
    nameClient = '';
    codeCoco = '';
    isCoco = false;
    arrayConstancias: string[] = [];
    errorProcess = false;
    maxAttempts = false;
    maxiError = 0;
    codeProduct: string;
    tipoAutenticacion: string = "";
    userrolid: string = sessionStorage.getItem(AppConstants.Session.USERROLID);
    constructor(
        private alertaService: AlertService,
        private solicitudService: SolicitudService,
        private router: Router,
        public modalRef: BsModalRef,
        private verificationService: VerificationService,
        private clientService: ClientService,
        private storageService: StorageService,
        private platform: Platform,
        private notificationService: NotificationService,
    ) {
    }

    ngOnInit() {
        this.isLoadingProcess = true;
        this.initializeParams();
        this.CallFinalizar();
    }

    downloadConstances(): void {
        for (const contance of this.arrayConstancias) {
            this.isLoadingProcess = true;
            this.clientService.downloadConstanciaRestriction(contance).subscribe(
                (response) => {
                    const data: Blob = new Blob([response], {
                        type: response.type
                    });
                    const nameFile = `${contance}.pdf`;
                    if (!this.platform.is('cordova')) {
                        FileSaver.saveAs(data, nameFile);
                    } else {
                        this.storageService.WriteFileMovil(nameFile, data);
                    }

                },
                (error) => {
                    this.alertaService.showMessage(AppConstants.TitleModal.DOWNLOAD_FILE, error.messages, MessageSeverity.error);
                }
            ).add(() => {
                this.isLoadingProcess = false;
            });
        }
    }

    FinalizarSolicitud(mode: SolicitudPut) {
        console.log('FinalizarSolicitud');
        console.log(mode);
        this.solicitudService.updateStateFinish(this.model).subscribe(async (response) => {
            this.arrayMessages = [...response.messages.filter(f => f.status !== 'NA')];
            console.log(this.arrayMessages);
            this.maxAttempts = response.maxAttempts;
            this.maxiError = 0;
            if (response.codigo === 400) {
                this.errorProcess = true;
                this.isLoadingProcess = false;
                this.classmessage = 'modal-texto-error';
                this.messagestatusResult = this.maxAttempts === true ? 'Usted superó el número de reintentos, favor comunicarse con el área de soporte.' : 'Finalizado con Error, porfavor vuelva a intentar';
            } else if (response.pending && !response.finish) {
                this.errorProcess = false;
                this.CallFinalizar();
            } else if (!response.pending && response.finish) {
                this.errorProcess = false;
                this.classmessage = 'modal-texto-exito';
                this.messagestatusResult = 'FINALIZADO CON EXITO';
                this.nameClient = response.cocoClientFullName;
                this.codeCoco = response.cocoCode;
                this.isCoco = response.isCoco;

                if (response.ConstanciasActivacion && response.ConstanciasActivacion.length > 0) {
                    this.arrayConstancias = response.ConstanciasActivacion.filter(x => x);
                } else {
                    this.arrayConstancias = [];
                }

                this.isLoadingProcess = false;
                switch (this.type) {
                    case 'TRAY_VIEW':
                        this.alertaService.showMessage(AppConstants.TitleModal.CREDIT_MANAGEMENT,
                            AppConstants.MessageModal.CREDIT_MANAGEMENT_OK, MessageSeverity.success);
                        this.router.navigateByUrl('bandeja');
                        break;
                    case 'VALIDATION_VIEW':
                        this.router.navigateByUrl('validacion/succes');
                        break;
                    case 'TRAY_VIEW_SUPERVISOR':
                        // tslint:disable-next-line:no-shadowed-variable
                        // await this.verificationService.validateDactilar(this.verificacion).toPromise().then((response) => {
                        // });
                        this.alertaService.showMessage(AppConstants.TitleModal.CREDIT_MANAGEMENT,
                            AppConstants.MessageModal.CREDIT_MANAGEMENT_OK, MessageSeverity.success);
                        this.router.navigateByUrl('bandeja');
                        break;
                }
            } else {
                this.isLoadingProcess = false;
            }
        },
            (error: HttpErrorResponse) => {
                // this.loading = false;
                this.isLoadingProcess = false;
                this.errorProcess = true;
                this.maxiError = this.maxiError + 1;
                if (this.maxiError == 3) {
                    this.maxAttempts = true;
                }
                let responseError = error.error;
                console.log('responseError error', error);
                if (responseError) {
                    if (responseError.code === 409) {
                        this.errorProcess = true;
                        this.isLoadingProcess = false;
                        this.classmessage = 'modal-texto-error';
                        this.messagestatusResult = responseError.messages[0];
                        this.alertaService.showMessage(AppConstants.TitleModal.CREDIT_MANAGEMENT, this.messagestatusResult, MessageSeverity.error);
                        return;
                    }
                }
                switch (this.type) {
                    case 'TRAY_VIEW':
                        this.alertaService.showMessage(AppConstants.TitleModal.PROCESS_MANAGEMENT, error.error.messages,
                            MessageSeverity.error);
                        break;
                    case 'VALIDATION_VIEW':
                        this.alertaService.showMessage(AppConstants.TitleModal.PROCESS_MANAGEMENT, error.error.messages,
                            MessageSeverity.error);
                        break;
                    case 'TRAY_VIEW_SUPERVISOR':
                        this.alertaService.showMessage(AppConstants.TitleModal.PROCESS_MANAGEMENT, error.error.messages,
                            MessageSeverity.error);
                        break;
                }
            });
    }

    CallFinalizar() {

        if (this.codeProduct == AppConstants.Products.EFEX) {
            this.FinalizarSolicitudEFEX(this.model);
        } else {
            this.FinalizarSolicitud(this.model);
        }
    }

    closeAlertModal() {
        this.modalRef.hide();
    }

    retryAgain() {
        this.isLoadingProcess = true;
        this.errorProcess = false;
        this.arrayMessages = [];
        this.messagestatusResult = '';
        this.classmessage = '';
        this.CallFinalizar();
    }

    FinalizarSolicitudEFEX(mode: SolicitudPut) {
        this.solicitudService.updateStateFinishEFEX(this.model).subscribe(async (response) => {
            this.arrayMessages = [...response.messages.filter(f => f.status !== 'NA')];
            console.log(this.arrayMessages);
            this.maxAttempts = response.maxAttempts;
            this.maxiError = 0;
            if (response.codigo === 400) {
                this.errorProcess = true;
                this.isLoadingProcess = false;
                this.classmessage = 'modal-texto-error';
                this.messagestatusResult = this.maxAttempts === true ? 'Usted superó el número de reintentos, favor comunicarse con el área de soporte.' : 'Finalizado con Error, porfavor vuelva a intentar';

                if (this.maxAttempts) {
                    this.sendMonitor(MONITOR_ANSWER_CODE.PIN_VALIDATION_MULTIPLE_FAILURE, this.tipoAutenticacion, this.model.solId);
                } else {
                    this.sendMonitor(MONITOR_ANSWER_CODE.DENIED_GENERAL, this.tipoAutenticacion, this.model.solId);
                }

            } else if (response.pending && !response.finish) {
                this.errorProcess = false;
                this.CallFinalizar();
            } else if (!response.pending && response.finish) {
                this.errorProcess = false;
                this.classmessage = 'modal-texto-exito';
                this.messagestatusResult = 'FINALIZADO CON EXITO';
                this.nameClient = response.cocoClientFullName;
                this.codeCoco = response.cocoCode;
                this.isCoco = response.isCoco;

                if (response.ConstanciasActivacion && response.ConstanciasActivacion.length > 0) {
                    this.arrayConstancias = response.ConstanciasActivacion.filter(x => x);
                } else {
                    this.arrayConstancias = [];
                }

                this.sendMonitor(MONITOR_ANSWER_CODE.APPROVED_GENERAL, this.tipoAutenticacion, this.model.solId);


                this.isLoadingProcess = false;
                switch (this.type) {
                    case 'TRAY_VIEW':
                        this.alertaService.showMessage(AppConstants.TitleModal.CREDIT_MANAGEMENT,
                            AppConstants.MessageModal.CREDIT_MANAGEMENT_OK, MessageSeverity.success);
                        this.router.navigateByUrl('bandeja');
                        break;
                    case 'VALIDATION_VIEW':
                        this.router.navigateByUrl('validacion/succes');
                        break;
                    case 'TRAY_VIEW_SUPERVISOR':
                        this.alertaService.showMessage(AppConstants.TitleModal.CREDIT_MANAGEMENT,
                            AppConstants.MessageModal.CREDIT_MANAGEMENT_OK, MessageSeverity.success);
                        this.router.navigateByUrl('bandeja');
                        break;
                }
            } else {
                this.isLoadingProcess = false;
            }
        },
            (error: HttpErrorResponse) => {
                // this.loading = false;
                this.isLoadingProcess = false;
                this.errorProcess = true;
                this.maxiError = this.maxiError + 1;
                if (this.maxiError == 5) {
                    this.maxAttempts = true;
                    if (this.maxAttempts) {
                        this.sendMonitor(MONITOR_ANSWER_CODE.PIN_VALIDATION_MULTIPLE_FAILURE, this.tipoAutenticacion, this.model.solId);
                    }
                } else {
                    this.sendMonitor(MONITOR_ANSWER_CODE.DENIED_GENERAL, this.tipoAutenticacion, this.model.solId);
                }
                let responseError = error.error;
                console.log('responseError error', error);
                if (responseError) {
                    if (responseError.code === 409) {
                        this.errorProcess = true;
                        this.isLoadingProcess = false;
                        this.classmessage = 'modal-texto-error';
                        this.messagestatusResult = responseError.messages[0];
                        this.alertaService.showMessage(AppConstants.TitleModal.CREDIT_MANAGEMENT, this.messagestatusResult, MessageSeverity.error);
                        return;
                    }
                }
                switch (this.type) {
                    case 'TRAY_VIEW':
                        this.alertaService.showMessage(AppConstants.TitleModal.PROCESS_MANAGEMENT, error.error.messages,
                            MessageSeverity.error);
                        break;
                    case 'VALIDATION_VIEW':
                        this.alertaService.showMessage(AppConstants.TitleModal.PROCESS_MANAGEMENT, error.error.messages,
                            MessageSeverity.error);
                        break;
                    case 'TRAY_VIEW_SUPERVISOR':
                        this.alertaService.showMessage(AppConstants.TitleModal.PROCESS_MANAGEMENT, error.error.messages,
                            MessageSeverity.error);
                        break;
                }
            });
    }

    async sendMonitor(codRespuesta: string, tipoAutenticacion: string, solId: string) {
        await this.notificationService.postRegisterMonitor(codRespuesta, tipoAutenticacion, solId).toPromise().then();
    }

    initializeParams() {

        if (this.codeProduct == AppConstants.Products.EFEX) {
            if (this.userrolid == AppConstants.RolesCode.PROVEEDORCALLCENTER) {
                this.tipoAutenticacion = MONITOR_AUTENTICATION_TYPE.OTP;
            } else {
                this.tipoAutenticacion = `${MONITOR_AUTENTICATION_TYPE.BIOMETRIC}${MONITOR_AUTENTICATION_TYPE.OTP}${MONITOR_AUTENTICATION_TYPE.PIN}`;
            }
        }
    }

}
