import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PinpadConnectService } from '../../services/pinpad-connect.service';
import { SolicitudService } from '../../../services/solicitud.service';
import { RequestValidatePinpadTicket, ResponseValidate } from '../../../models/solicitud';
import { Parameter } from '../../../models/parameter';
import { HttpErrorResponse } from '@angular/common/http';
import { ParameterService } from '../../../services/parameter.service';
import { AppConstants, PINPAD_ERROR_CODE } from '../../constants/app.constants';
import { NotificationService } from '../../../services/notification.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';

@Component({
  selector: 'onb-pinpad-button',
  templateUrl: './onb-pinpad-button.component.html',
  styleUrls: ['./onb-pinpad-button.component.scss'],
})
export class OnbPinpadButtonComponent implements OnInit {

  loading: boolean = false;
  @Input() parameters: string;
  @Output() ConnectPINPAD = new EventEmitter<any>();
  ticketResult: any;
  byPassPinPad: boolean = false;

  constructor(
    private pinpadConnectService: PinpadConnectService,
    private solicitudService: SolicitudService,
    private parameterService: ParameterService,
    private notificationService: NotificationService,
    private alertaService: AlertService,) {

    this.GetParameters([
      AppConstants.ParameterCode.ADICIONAL_PARAMETERS
    ]);
  }
  ngOnInit(): void {

  }

  async GetParameters(array: Array<number>) {
    let modelRequest = { headerId: array };
    this.loading = true;

    // registrar movimiento
    await this.parameterService.getParametersList(modelRequest).toPromise().then((response) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
      (error: HttpErrorResponse) => {
        this.loading = false;
      });
  }

  setVariablesParameters(listParameter: Parameter[]) {
    this.byPassPinPad = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.ADICIONAL_PARAMETERS
      && f.tabDetState == AppConstants.StateFlag.Activo
      && f.tabDetCode001 == "15"
      && f.tabDetCode006 == "SI").length > 0 ? true : false;
  }

  async OnConnectPINPAD() {
    this.loading = true;

    await this.notificationService.postRegisterTracePinpad(this.parameters).toPromise().then((response) => {

    },
      (error: HttpErrorResponse) => {
        console.log('error', error);
        this.ConnectPINPAD.emit(this.setResultError(PINPAD_ERROR_CODE.ERROR_REGISTER_TRACE));
        this.loading = false;
      });
      
    console.log('this.byPassPinPad', this.byPassPinPad);
    if (this.byPassPinPad) {
      let result: ResponseValidate = new ResponseValidate();
      result.isValid = true;
      result.resultCode = "";
      this.ConnectPINPAD.emit(result);
      this.loading = false;
      return;
    }


    let ticketNumber = await this.pinpadConnectService.ConnectPINPAD();
    if (ticketNumber != null) {

      let model: RequestValidatePinpadTicket = new RequestValidatePinpadTicket();      
      model.solId = this.parameters;

      model.ticketNumber = JSON.parse(ticketNumber).CRSTicketNumber;
      console.log('model', model);
      if (model.ticketNumber == undefined || model.ticketNumber == "" || model.ticketNumber == null) {
        //this.alertaService.showMessage(AppConstants.TitleModal.VALIDATION_PINPAD, AppConstants.MessageModal.MESSAGE_PINPAD_ERROR, MessageSeverity.error);
        this.ConnectPINPAD.emit(this.setResultEmpty());
        this.loading = false;

      } else {
        this.solicitudService.validatePinpadTicket(model).toPromise().then((response) => {
          console.log("resultado validar ticket!!!", response);
          this.ConnectPINPAD.emit(response);
          this.loading = false;
        },
          (error: HttpErrorResponse) => {
            console.log('error', error);
            this.ConnectPINPAD.emit(this.setResultError(PINPAD_ERROR_CODE.ERROR_VALIDATE_SERVICE_TICKET));
            this.loading = false;
          });
      }

    } else {
      this.ConnectPINPAD.emit(this.setResultEmpty());
      this.loading = false;
    }
  }

  setResultEmpty(): ResponseValidate {
    let result: ResponseValidate = new ResponseValidate();
    result.isValid = false;
    result.resultCode = null;
    return result;
  }

  setResultError(code: string): ResponseValidate {
    let result: ResponseValidate = new ResponseValidate();
    result.isValid = false;
    result.resultCode = code;
    return result;
  }

}
