export const environment = {
    production: true,
    sistemaWebAdmin: "https://service.onboarding.dev.bancoripley.com.pe/api",
    identityApiEndPointToken: "https://service.onboarding.dev.bancoripley.com.pe/identity/connect/token",
    ripleyEndPointAPI: "https://gw.api.dev.bancoripley.com.pe/ripley-banco-dev",
    reCaptchaKey: "6LePgeIUAAAAAHOZ3ObQaVHcgCzEimjomrXdPIZ1",
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyC7jk8hx0iodzOWReANTPSDPAx659E8urc',
      authDomain: 'hbk-dev.firebaseapp.com',
      databaseURL: 'https://hbk-dev.firebaseio.com',
      projectId: 'hbk-dev',
      storageBucket: 'hbk-dev.appspot.com',
      messagingSenderId: '54992857958',
      appId: '1:54992857958:web:db0c8590a1abeaa04236a7'
    },
    serviceIP_ONE: "https://api.ip.sb/geoip",
    serviceIP_TWO: "https://jsonip.com",
    serviceBus: "https://bqa2lb01p.bancoripley.pe:7847/BS_01/CRSystemsOperationsTicket/s/v1.0?wsdl"
  };