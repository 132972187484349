import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HelperService } from '../shared/services/helper.service';
import { UserValidateResponse, UserValidateRequest, UserPasswordModel } from '../models/user';
import { Observable } from 'rxjs';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

    constructor(
        private httpClient: HttpClient
    ) { }

    private POST_ENDPOINT: string = "User";
    private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
    private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

    createUser(user: User): Observable<User> {
        return this.httpClient.post<User>(`${this.REQUEST_URL}/CreateUser`, user);
    }

    updateUser(user: User): Observable<User> {
        return this.httpClient.put<User>(`${this.REQUEST_URL}/UpdateUser`, user);
    }
    getUsuarios(parameters: any) {
        let httpParams = new HttpParams({
            fromObject: parameters
        });
        return this.httpClient.get<User[]>(`${this.REQUEST_URL}/GetUsers`, { params: httpParams });
    }

    validateUser(model: UserValidateRequest): Observable<UserValidateResponse> {
        return this.httpClient.post<UserValidateResponse>(`${this.REQUEST_URL}/ValidateUser`, model);
    }

    updatePassword(userPasswordParameters: UserPasswordModel): Observable<UserPasswordModel> {
        return this.httpClient.put<UserPasswordModel>(`${this.REQUEST_URL}/updatePassword`, userPasswordParameters);
    }

    updateAgency(model: any): Observable<number> {
        return this.httpClient.put<number>(`${this.REQUEST_URL}/updateAgency`, model);
    }

    unlockUser(userId: User): Observable<boolean> {
        return this.httpClient.put<boolean>(`${this.REQUEST_URL}/UnlockUser`, userId);
    }
}
