import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {HelperService} from '../shared/services/helper.service';
import {environment} from '../../environments/environment';
import {
    Client,
    ClientLoan,
    ClientReniec,
    CreateClient,
    CreateClientCappta,
    CreateClientReprogramming,
    FileDeleteClient,
    FilesClient,
    RequestClientLoan,
    RequestClientReprogramming,
    RequestClientReprogrammingSef,
    RequestGetCardDetail,
    RequestGetLoanDetail,
    RequestGetProducts,
    RequestUpdateCardRestrictions,
    RequestValidateAccount,
    RequestValidateReprogramming,
    RequestValidationLoan,
    RequestValidationPoliticalAndBlocks,
    ResponseClientInsurance,
    ResponseClientReprograming,
    ResponseClientReprogramingSef,
    ResponseGetCardRestrictions,
    ResponseGetCardsClient,
    ResponseGetClientT24,
    ResponseGetHistoryRestrictions,
    ResponseUpdateCardRestrictions,
    ResponseValidateAccount,
    ResponseValidateReprogramming,
    ResponseValidationLoan,
    ResponseValidationPoliticalAndBlocks,
    UpdateClientSimulationReprograming,
    UploadFile
} from '../models/client';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Entidades, EntidadesUpdate} from '../models/entidad';
import {Politica} from '../models/simular';

@Injectable({
    providedIn: 'root'
})
export class ClientService {

    constructor(
        private httpClient: HttpClient
    ) {
    }

    private POST_ENDPOINT: string = 'Client';
    private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
    private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

    createClient(client: CreateClient): Observable<Client> {
        return this.httpClient.post<Client>(`${this.REQUEST_URL}/Create`, client);
    }

    validationReengancheSefRsat(modelo: any): Observable<any> {
        return this.httpClient.post<any>(`${this.REQUEST_URL}/ValidationReengancheSefRsat`, modelo);
    }

    updateClient(client: Client): Observable<Client> {
        return this.httpClient.put<Client>(`${this.REQUEST_URL}/Update`, client);
    }

    getClient(code: string): Observable<Client> {
        return this.httpClient.get<Client>(`${this.REQUEST_URL}/${code}`);
    }

    uploadFiles(client: UploadFile): Observable<UploadFile> {
        return this.httpClient.post<UploadFile>(`${this.REQUEST_URL}/UploadFiles`, client);
    }

    getFilesClient(code: string, contition: number): Observable<FilesClient> {
        return this.httpClient.get<FilesClient>(`${this.REQUEST_URL}/${code}/${contition}`);
    }

    deleteFileClient(modelo: FileDeleteClient): Observable<FileDeleteClient> {
        return this.httpClient.post<FileDeleteClient>(`${this.REQUEST_URL}/DeleteFile`, modelo);
    }

    downloadFile(fileId: string): Observable<Blob> {
        return this.httpClient.post<Blob>(`${this.REQUEST_URL}/DownloadFile/${fileId}`, fileId,
            {responseType: 'blob' as 'json'})
            .pipe(catchError(this.parseErrorBlob));
    }

    parseErrorBlob(err: HttpErrorResponse): Observable<any> {
        const reader: FileReader = new FileReader();

        const obs = Observable.create((observer: any) => {
            reader.onloadend = (e) => {
                observer.error(JSON.parse(reader.result.toString()));
                observer.complete();
            };
        });
        reader.readAsText(err.error);
        return obs;
    }

    getEntidades(parameters: any) {
        let httpParams = new HttpParams({
            fromObject: parameters
        });
        return this.httpClient.get<Entidades[]>(`${this.REQUEST_URL}/GetEntidades`, {params: httpParams});
    }

    updateEntidades(entidades: EntidadesUpdate): Observable<any> {
        return this.httpClient.post<any>(`${this.REQUEST_URL}/UpdateEntidades`, entidades);
    }

    getClientReniec(typeDoc, numDoc: string, idSolicitud: string, productCode: string): Observable<ClientReniec> {
        return this.httpClient.get<ClientReniec>(`${this.REQUEST_URL}/Reniec?docType=${typeDoc}&documentNumber=${numDoc}&solId=${idSolicitud}&productId=${productCode}`);
    }

    validarPoliticarCorporativas(docType: string, docNumber: string, fullName: string, moneda: string) {
        return this.httpClient.get<Politica>(`${this.REQUEST_URL}/policies`,
            {
                params: new HttpParams({
                    fromObject: {
                        docType,
                        docNumber,
                        fullName,
                        moneda
                    }
                })
            });
    }

    validarPoliticarAndBlocks(filter: RequestValidationPoliticalAndBlocks) {
        return this.httpClient.post<ResponseValidationPoliticalAndBlocks>(`${this.REQUEST_URL}/validatingRestrinctions`, filter);
    }

    getClientLoan(modelo: RequestClientLoan): Observable<ClientLoan[]> {
        return this.httpClient.post<ClientLoan[]>(`${this.REQUEST_URL}/getLoan`, modelo);
    }

    validationLoan(modelo: RequestValidationLoan): Observable<ResponseValidationLoan> {
        return this.httpClient.post<ResponseValidationLoan>(`${this.REQUEST_URL}/LoanValidateConditions`, modelo);
    }

    getClientReprogramming(modelo: RequestClientReprogramming): Observable<ResponseClientReprograming[]> {
        return this.httpClient.post<ResponseClientReprograming[]>(`${this.REQUEST_URL}/getReprogramming`, modelo);
    }

    createClientReprogramming(client: CreateClientReprogramming): Observable<Client> {
        return this.httpClient.post<Client>(`${this.REQUEST_URL}/Create/Reprogramming`, client);
    }

    updateClientSimulationQuota(entidades: UpdateClientSimulationReprograming): Observable<number> {
        return this.httpClient.put<number>(`${this.REQUEST_URL}/UpdateClientReprograamingQuota`, entidades);
    }

    getClientReprogrammingSEF(modelo: RequestClientReprogrammingSef): Observable<ResponseClientReprogramingSef[]> {
        return this.httpClient.post<ResponseClientReprogramingSef[]>(`${this.REQUEST_URL}/getReprogrammingSEF`, modelo);
    }

    createClientCappta(client: CreateClientCappta): Observable<Client> {
        return this.httpClient.post<Client>(`${this.REQUEST_URL}/Create/Cappta`, client);
    }

    getValidationReprograming(modelo: RequestValidateReprogramming): Observable<ResponseValidateReprogramming> {
        return this.httpClient.post<ResponseValidateReprogramming>(`${this.REQUEST_URL}/validateReprograming`, modelo);
    }

    getClientT24(typeDocument: string, documentNumber: string): Observable<ResponseGetClientT24> {
        const httpParams = new HttpParams({
            fromObject: {docType: typeDocument, documentNumber: documentNumber}
        });
        return this.httpClient.get<ResponseGetClientT24>(`${this.REQUEST_URL}/T24`, {params: httpParams});
    }

    getCardsClient(idClient: string, productTypeName: string, nroDocumento?:string,tipoDocumento?:string): Observable<ResponseGetCardsClient[]> {
        const httpParams = new HttpParams({
            fromObject: {
                idClient: idClient,
                productTypeName: productTypeName,
                nroDocumento: nroDocumento,
                tipoDocumento: tipoDocumento
            }
        });
        return this.httpClient.get<ResponseGetCardsClient[]>(`${this.REQUEST_URL}/GetCards`, {params: httpParams});
    }

    getRestrictions(cardNumber: string): Observable<ResponseGetCardRestrictions> {
        return this.httpClient.post<ResponseGetCardRestrictions>(`${this.REQUEST_URL}/GetRestrictions`, {cardNumber});
    }

    getHistoryRestrictions(cardNumber: string): Observable<ResponseGetHistoryRestrictions[]> {
        return this.httpClient.post<ResponseGetHistoryRestrictions[]>(`${this.REQUEST_URL}/GetHistoryRestrictions`,
            {numeroTarjeta: cardNumber});
    }

    updateRestrictions(modelo: RequestUpdateCardRestrictions): Observable<ResponseUpdateCardRestrictions> {
        return this.httpClient.post<ResponseUpdateCardRestrictions>(`${this.REQUEST_URL}/UpdateRestrictions`, modelo);
    }

    downloadConstanciaRestriction(numeroConstancia: string): Observable<Blob> {
        return this.httpClient.get<Blob>(`${this.REQUEST_URL}/DownloadConstanciaRestriccion/${numeroConstancia}`,
            {responseType: 'blob' as 'json'})
            .pipe(catchError(this.parseErrorBlob));
    }

    downloadFileHistory(cardNumber: string): Observable<Blob> {
        return this.httpClient.post<Blob>(`${this.REQUEST_URL}/DownloadHistoryRestrictions`,
            {numeroTarjeta: cardNumber}, {responseType: 'blob' as 'json'})
            .pipe(catchError(this.parseErrorBlob));
    }

    getInsurance(account: string) {
        return this.httpClient.get<ResponseClientInsurance[]>(`${this.REQUEST_URL}/GetInsurance/${account}`);
    }

    getDetailTCByNumber(modelo: RequestGetCardDetail): Observable<any> {
        return this.httpClient.post<any>(`${this.REQUEST_URL}/GetCardDetail`, modelo);
    }

    getProductsByDocument(modelo: RequestGetProducts): Observable<any> {
        return this.httpClient.post<any>(`${this.REQUEST_URL}/ConsultProducts`, modelo);
    }

    getDetailLoanPaidFee(modelo: RequestGetLoanDetail): Observable<any> {
        return this.httpClient.post<any>(`${this.REQUEST_URL}/GetLoanDetail`, modelo);
    }

    ValidateAccount(model: RequestValidateAccount): Observable<ResponseValidateAccount> {
        return this.httpClient.post<ResponseValidateAccount>(`${this.REQUEST_URL}/ValidateAccount`, model);
      }
}
