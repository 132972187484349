export class Solicitud {
}

export class SolicitudPut {
    solId: string;
    worFlowId: string;
    solState: number;
    userId: string;
    userRol: string;
    processId: number;
    channel: number;
    taskId: string;
}

export class SolicitudPutProduct {
    solId: string;
    worFlowId: string;
    solAmount: number;
    solQuotaNumber: number;
    solPeriodGra: number;
    solPaymentDay: number;
    solDateFirstPayment: string;
    solValueQuota: number;
    solSaldoCDD: number;
    solTCEA: number;
    simulatorAmmount: number;
    userId: string;
    userRol: string;
    processId: number;
    channel: number;
    taskId: string;
    seguroProdDato: string;
    seguroDesgravamen: string;
    applyTF: string;
    solTipoRescate: string;
    solTasaRescate: number;
}

export class RequestReturnSolicitud {
    documentType: string;
    documentNumber: string;
}

export class ResponseReturnSolicitud {
    solId: string;
    estado: number;
    movil: string;
    web: string;
    wfInstance: string;
    userCreation: string;
    variableControl: VariableControl;
    producto: ProductoSolicitutResponse;
}

export class VariableControl {
    variableControl: string;
    biometrico: string;
    token: string;
    creditos: string;
    enviarSupervisor: string;
    enviarCreditos: string;
    enviarSMSToken: string;
    proceso: number;
    pinpad: string;
}

export class ProductoSolicitutResponse {
    nombre: string;
    activacion: string;
    codigo: string;
    saldo: string;
    baseOrigen: string;
    image: string;
    intentosToken: number;
    indicadorOrigenSef: string;
    ofeGracePeriodMax: string;
}

export class FechasSolicitud {
    fechaInicioModificacion: string;
    fechaFinModificacion: string;
}

export class ResponseFinalizarSolicitud {
    codigo: number;
    pending: boolean;
    finish: boolean;
    messages: Array<MessagesFinalizarSolicitud> = [];
    cocoCode: string;
    cocoClientFullName: string;
    isCoco: boolean;
    ConstanciasActivacion: string[];
    maxAttempts: boolean;
}

export class MessagesFinalizarSolicitud {
    message: string;
    status: string;
}

export class ResponseValidateOfferReeganche {
    offerTotalSolicitud: number;
    offerTotalAmmount: number;
    offerAmmount: number;
    ammountFree: number;
    message: string;
}

export class RequestUpdateSolicitudCoco {
    solId: string;
    solFechPago: number;
    solRecepcionEstadoCuenta: string;
    solEnvioEstadoCuenta: number;
    solNuevaDireccion: string;

    solComprasInternet: boolean;
    solComprasExtranjero: boolean;
    solRecibirNotificaciones: boolean;
    solComentarioRestriccion: string;
    solFlagSeguro: boolean;
    creditCardCode: string;
    cashprovision: boolean;
    overdraft: boolean;
    notification: boolean;
    clientPEP: boolean;
    createSavingAccount: boolean;
    aceptaCuentaAhorro:boolean;
    tipoCuentaAhorro: string;
}

export class ValidateStateSolicitud {
    active: boolean;
    state: number;
}

export class ValidateDecline {
    isBlock: boolean;
}


export class OperacionesEfex{
    numeroSolicitud: string;
    estado: number;
    estadoDesc: string;
    fechaSolicitud: string;
    codigoProducto: string;
    modoActivacion: string;
    nombreProducto: string;
    tipoDocumento: string;
    numeroDocumento: string;
    montoDesembolso: number;
    tipoDesembolso: string;
    cliente: string;
    vendedor: string;
    usuarioCajero: string;
    color: string;
    imagenproducto: string;
    imagenproducto_m: string;
    offerCodBase: string;
    workflowInstance: string;
    ofeBaseOrigen: string;
    entSolicitud: number;
    indicadorOrigenSef:string;
    offerCodIndConvPef:string;
}

export class AnularSolicitudRequest {
    solId: string;
    worFlowId: string;
    solState: number;
    userId: string;
    userRol: string;
    processId: number;
    channel: number;
    taskId: string;
    modDesembolso: string;
    salesChannel:number;
}

export class RequestAnularSolicitud {
    solId: string;
    worFlowId: string;
    solState: number;
    userId: string;
    userRol: string;
    processId: number;
    channel: number;
    taskId: string;
}

export class ResponseAnularSolicitud {
    codigo: number;
    pending: boolean;
    finish: boolean;
    messages: Array<MessagesFinalizarSolicitud> = [];
    cocoCode: string;
    cocoClientFullName: string;
    isCoco: boolean;
    ConstanciasActivacion: string[];
    maxAttempts: boolean;
}

export class RequestValidatePinpadTicket {
    solId: string;
    ticketNumber: string;
}
export class ResponseValidate {
    isValid: boolean;
    resultCode: string;
    message: string;
}