import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AmountCocoResponse, BankComissionResponse, BankResponse, EmployedResponse, ProductRateResponse, ActiveProductResponse, KeyConfigurationResponse   } from '../models/onboarding-integration';
import { HelperService } from '../shared/services/helper.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingIntegrationService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "Integration";
  private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getUserAgency(parameter: any): Observable<EmployedResponse> {
    return this.httpClient.post<EmployedResponse>(`${this.REQUEST_URL}/GetUserAgency`, parameter);
  }

  getBanksSchedule(): Observable<any> {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/GetBanksSchedule`);
  }

  getBankByCode(code: string): Observable<BankResponse> {
    return this.httpClient.get<BankResponse>(`${this.REQUEST_URL}/GetBank/${code}`);
  }

  getBankComission(model: any): Observable<BankComissionResponse> {
    return this.httpClient.post<BankComissionResponse>(`${this.REQUEST_URL}/BankCalculeComision`, model);
  }

  getAmountCoco(): Observable<AmountCocoResponse> {
    return this.httpClient.get<AmountCocoResponse>(`${this.REQUEST_URL}/ValidateAmountCoco`);
  }

  getDataMoraByContract(model: any): Observable<any> {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/GetDataMoraByContracts`, model);
  }
  
  getAmountEfex(contractNumber: string): Observable<any> {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/GetContractByAccount`, {contractNumber});    
  }
  getProductRate(parameter: any): Observable<ProductRateResponse> {
    return this.httpClient.post<ProductRateResponse>(`${this.REQUEST_URL}/GetProductRate`, parameter);    
  }

  getActiveProduct(parameter: any): Observable<ActiveProductResponse> {
    return this.httpClient.post<ActiveProductResponse>(`${this.REQUEST_URL}/GetActiveProduct`, parameter);    
  }

  getKeyConfiguration(parameter: any): Observable<KeyConfigurationResponse> {
    return this.httpClient.post<KeyConfigurationResponse>(`${this.REQUEST_URL}/GetKeyConfiguration`, parameter);    
  }
}
